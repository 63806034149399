<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="240"
    v-bind="$attrs"
  >
    
    <v-list dense nav>
      <v-list-item>
          <v-img src="@/assets/logo.svg"/>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
    
        <base-item-group
          v-if="item.children" :key="`group-${i}`" :item="item" >
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    
    <template v-slot:append>
      <div @click="showDelog()">
        <base-item :item="{ title: $t('log_out'),icon: 'el-icon-switch-button' }" />
      </div>

      <!-- <el-divider></el-divider> -->
      <div class="ml-5" style="color:#ffff; font-size:10px; text-align:left;"> <p> version du logiciel {{versionApp}} </p></div>
    </template>
  </v-navigation-drawer>
</template>

<script>

  // Utilities
  import { mapState,} from 'vuex';
  import NProgress from 'nprogress';
  import 'nprogress/nprogress.css';
  import axios from 'axios';
  import package_json from "../../../../../package.json";


  export default {

    name: 'X-Performance',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data() {

      return {

        items: [
          {
            icon: 'el-icon-menu',
            title: this.$t('sidebar.home'),
            to: '/',
          },
          {
            icon: 'el-icon-s-order',
            title: this.$t('sidebar.report'),
            to: '/pages/rapport',
          },
          {
            icon: 'el-icon-data-analysis',
            title: this.$t('sidebar.statistics'),
            to: '/pages/statistique',
          },
          {
            icon: 'el-icon-s-cooperation',
            title: this.$t('sidebar.administrations'),
            to: '/pages/administration',
          },
          {
            icon: 'fa-solid fa-bullhorn',
            title: this.$t('sidebar.communication'),
            to: '/pages/communication',
          },
          {
            icon: 'fa-solid fa-users',
            title: this.$t('sidebar.crm'),
            to: '/pages/crm',
          },
          {
            icon: 'fa-solid fa-circle-user',
            title: this.$t('sidebar.my_account'),
            to: '/pages/profile',
          },
          {
            icon: 'el-icon-setting',
            title: this.$t('sidebar.setting'),
            to: '/pages/parametre',
          }
          
        ],

        versionApp : '',
        res_menu : [],
      }

    },

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {

      mapItem (item) {

        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : '',
          title: this.$t(item.title),
        }
      },

      showDelog(){
          this.$swal({
          title: 'Confirmer ?',
          text: "Vous ête sur le point d'être déconnecter poursuivre ?",
          type: 'question',
          // showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d13737',
          confirmButtonText: 'Oui'
        }).then((result) => {
          if (result.value) {
            this.logout();
          }
        });
      },

      logout() {
          NProgress.start();
          localStorage.clear();
          this.$auth.logout({
                makeRequest: true,
                redirect: {name: 'login'},
          });

          NProgress.done();
      },

      getMenuSection() {
        
        // Récupération des acces pour le menu
        NProgress.start();
        try {

          var menu = (JSON.parse(localStorage.getItem('menu'))).menu.menu;

          console.log('var', menu);

          menu.forEach(element => {
            this.res_menu.push(element);
          });

            this.buildMenu();
        } 
        catch (error) {
          this.$notify({
              type: 'error',
              title: "Echec de "
          });
          NProgress.done();
        }

      },

      // Fonction pour la creation d'un menu dynamique
      buildMenu() {

        if(!this.res_menu.includes('report')) {
            console.log('report');
            this.items.splice(1);
        }
        if(!this.res_menu.includes('statistique')) {
            console.log('statistique');
            this.items.splice(2);
        }
        if(!this.res_menu.includes('administration')) {
            console.log('administration');
            this.items.splice(3);
        }
        if(!this.res_menu.includes('communication')) {
            console.log('out');
            this.items.splice(4);
        }
        // if(!this.res_menu.includes('clients')) {
        //     console.log('clients');
        //     this.items.splice(5);
        // }
        if(!this.res_menu.includes('setting')) {
            console.log('setting');
            this.items.splice(7);
        }

        NProgress.done();
      },

      reloadPage() {
        window.location.reload();
      }

    },
    
    created() {
      this.versionApp = package_json.version;
    },
    mounted(){
      setTimeout(this.getMenuSection(), 5000);
    },

    // warch : {
    //   res_menu :function () {
    //     console.log('in');
    //     // this.buildMenu();
    //   }
    // },
  
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
